@tailwind base;
@tailwind components;
@tailwind utilities;


/* styles.css */
@keyframes car-drive {
    100% { right: -900px; }
    0% { right: 100%; }
  }
  
  .traffic {
    position: relative;
    width: 100%;
    height: 100px;
    overflow: hidden;
    margin-top: 1px; /* Add some space between timer and traffic */
  }
  
  .car {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url('Images/Car.svg') no-repeat center center;
    background-size: contain;
    animation: car-drive 5s linear infinite;
  }
  .CustomFont {
    font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  }
  
  